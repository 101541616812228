<template>

  <div class="portofolios bg-white" ref="portofolios">
    <div class="container py-20 px-5">
      <div class="mb-10">
        <h1 class="text-gold font-lora mb-3">Portofolio saya. <span class="text-light text-base">- My works.</span></h1>
        <p class="text-light">
          These are some featured projects that i have been working on so far. Credit to all friends, partners, colleagues, and
          everyone involved at these projects.
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mb-10">
        <div v-for="(porto, index) in portos" :key="index"
          class="rounded-lg p-5 transition duration-300 hover:shadow-xl transform hover:-translate-y-1 flex flex-col">
          <img :src="porto.logo" :alt="porto.name" class="h-12 mb-5 mx-auto">
          <div>
            <p class="text-dark mb-1">{{ porto.name }}</p>
            <p class="text-light text-sm mb-1">{{ porto.desc }}</p>
            <a :href="porto.link" target="_blank" class="text-gold font-lato underline text-sm" v-if="porto.link">Live
              Available</a>
            <p class="text-light font-lato underline text-sm cursor-not-allowed" v-else>Live Not Available</p>
          </div>
        </div>
      </div>

      <div class="text-center">
        <router-link to="/works" class="">
          <button class="rounded border-2 py-1 px-5 text-gold font-lato border-gold transition duration-300 transform hover:-translate-y-1">
            See More
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Portofilios',
    data() {
      return {
        portos: [
          {
            logo: '/porto/simulasiedu/logo.png',
            name: 'Simulasiedu',
            desc: 'Online exam platform by akselerasi group. Provide students or graduates to make ready beforehand for a real exam (e.g. UTBK, STAN, etc).',
            link: 'https://simulasiedu.com/',
          },
          {
            logo: '/porto/lingkaran-team-roles/logo.png',
            name: 'lingkaran Team Roles',
            desc: 'A fun personality test to discover user best role in a team. The result form as archetype, each archetype have unique strengths, weaknesses, and powerfull colaborators.',
            link: 'https://lingkaran.co/lingkaran-team-roles/',
          },
          {
            logo: '/porto/pokepon/logo.png',
            name: 'Pokepon - Pokemon Gashapon',
            desc: `Having fun with React.js and pokemon GraphQL API, pokepon are mini games that I build inspired by japanese toy-vending machine "Gashapon". Gotta catch 'em all!`,
            link: 'https://yohanesisa.github.io/pokepon/#/',
          },
          // {
          //   logo: '/porto/crowde/logo.png',
          //   name: 'CROWDE',
          //   desc: 'A P2P lending platform to support farmers through agricultural capital and technological innovation, with a vision to raise farmers well being.',
          //   link: 'https://crowde.co/',
          // },
        ]
      }
    },
    methods: {

    }
  }
</script>
<style>
</style>