<template>

  <div class="bg-white">
    <div class="container py-20 px-5">
      <h1 class="text-gold font-lora mb-3">Apa kabar? <span class="text-light text-base">- How are you?</span></h1>
      <p class="text-light mb-1">
        Hi, you can call me <strong><span class="text-dark">Isa</span></strong>.
        I'm a detail-oriented and eager learner developer who passionate at web platform application development with {{ new Date().getFullYear() - 2018 }}+ years of professional experiences.  
        I mainly handle the frontend side of development using some popular frameworks such as Nuxt or Vue and currently exploring other technologies too such as React and Express.
      </p>
      <p class="text-light mb-2">
        It's a challenging thrill for me to grant user best experiences through collaboration of critical thinking, while also maintaining the best quality code at the same time.
        Currently seeking for opportunities that can leverage my skills and experience as Software Engineer in a team of product that have impactful vision
        for people and society.
      </p>
      <p class="text-light mb-5">
        Focusing at frontend side of web based software engineering, these are the technologies that I'm experience and currently exploring at:
      </p>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <div class="mb-3 sm:mb-0">
          <h4 class="text-gold font-lora mb-3">Experience</h4>
          <p class="text-dark text-sm mb-1">
            Programming Languages
          </p>
          <p class="text-light mb-4">
            HTML, CSS, JavaScript, PHP, Python, Java
          </p>
          <p class="text-dark text-sm mb-1">
            Technologies
          </p>
          <p class="text-light">
            Vue.js, Nuxt.js, React.js, Laravel, Bootstrap
          </p>
        </div>
        <div>
          <h4 class="text-gold font-lora mb-3">Exploring</h4>
          <p class="text-dark text-sm mb-1">
            Technologies
          </p>
          <p class="text-light">
            Tailwind CSS, React.js, Express.js
          </p>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

  export default {
    name: 'about',
    components: {
    },
  }
</script>
<style>
</style>