import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import "./assets/css/tailwind.css";

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: {
    id: 'G-XBTQ5VCWLJ',
    params: {
      send_page_view: true
    }
  }
}, router)

new Vue({
  router,
  render: h => h(App),
  metaInfo: () => ({
    title: 'yohanesisa | Yohanes Isa',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: `Hi, you can call me Isa. I'm a detail-oriented and eager learner developer who passionate at web platform application development with ${ new Date().getFullYear() - 2018 }+ years of professional experiences.` },
      { name: 'author', content: 'Yohanes Isa' },
      { name: 'keywords', content: 'yohanesisa, yohanes isa, software developer, software engineer, web developer, frontend' },
      { property: 'og:image', content: 'https://yohanesisa.com/image/og-image.jpeg' }
    ]
  })
}).$mount('#app')
